body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* .productBorder {
  border: 5px solid green;
  margin: 20px 0px;
}
 */

.buttonTop {
  background: white;
  border: none;
  border-radius: 4px;
  color: black;
  padding: 10px 20px;
  text-decoration: none;
  font-weight: bold;
  margin-left: 10px;
  margin-right: 10px;
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  background-color: blueviolet;
  border-bottom: 5px solid white;
  padding: 10px;
}
